<template>
  <div>
    <div class="isHcpBox">{{ isHcpUser === 'N' ? 'hcp 인증이 필요한 컨텐츠 입니다' : '인증확인 됨' }}</div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
export default {
  data() {
    return {
      isHcpUser: 'N',
    };
  },
  mounted() {
    console.log('this.$route.query', this.$route.query);

    this.isHcpUser = 'N';
    this.getHcpInfo(this.$route.query).then((res) => {
      if (res === undefined || res === null) return;
      console.log('getHcpInfo res', res.isHcp);
      this.isHcpUser = res.isHcp;

      this.saveCertifyCard(this.$route.query.contents).then(() => {
        // 리 다이렉션 처리
        const targetUrl = '/quickMenu/' + this.$route.query.contents;
        this.$router.push(targetUrl).catch(() => {});
      });
    });
  },
  methods: {
    ...mapActions(['getHcpInfo', 'saveCertifyCard']),
  },
};
</script>
<style lang="scss">
.isHcpBox {
  width: 100%;
  height: calc(100vh - 125px);
  background: white;
  text-align: center;
  padding: 150px 0;
}
</style>